import React, { Component } from "react"

import loginSession from '../../../lib/class/loginSession'

import Layout from "../../components/layout"
import GatedContent from "../../components/gatedcontent"

import styles from '../../styles/pages/selenium.module.css'


const requestJSON = require('../../../lib/requestJSON')

const loadingFileList = [
			{"id":".", "name":"Loading...", "browser":"", "mobile": true},
			{"id":".", "name":"Loading...", "browser":"", "mobile": false},
		];

export default class NegosyoNowApiIndex extends Component {
	state = {
		showdiffonly: false,
		showlogentry: false,
		recordleftid:"Please Select Log",
		recordrightid:"Please Select Log",
		recordlefttype:"",
		recordrighttype:"",
		recordleft:[],
		recordright:[],
		showinputleft:{},
		showinputright:{},
		listoutput:[],
		listreference:[],
		compareoutput:[],
		loginObj: null
	}

	componentDidMount() {
		this.setState({
			loginObj: new loginSession()
		}, function() {
			this.fetchFiles();
		});
	}

	reloadFiles = () => {
		this.fetchFiles();
	}

	toggleDiffItems = () => {
		this.setState(state => ({ 
			showdiffonly: !this.state.showdiffonly
		}));
	}

	toggleLogItems = () => {
		var tmpoutput = this.generateLog(this.state.recordleft, this.state.recordright, !this.state.showlogentry);
		this.setState(state => ({ 
			showlogentry: !this.state.showlogentry,
			compareoutput: tmpoutput
		}));
	}

	toggleRowInputRaw = (side, index) => {
		var key = "in"+index;
		var curobj = {};
		if (side === "left") {
			curobj = this.state.showinputleft;
		} else if (side === "right") {
			curobj = this.state.showinputright;
		} else {
			return;
		}
		if (curobj.hasOwnProperty(key)) {
			curobj[key] = !curobj[key];
		} else {
			curobj[key] = true;
		}

		if (side === "left") {
			this.setState(state => ({ 
				showinputleft: curobj
			}));
		} else if (side === "right") {
			this.setState(state => ({ 
				showinputright: curobj
			}));
		}

	}

	getlogrecord = (refindex, refrecordlist) => {
		var type = "";
		var msg = "-";
		var index = -1;
		if (refindex>=0) {
			if (refindex < refrecordlist.length) {
				type = refrecordlist[refindex].type.toLowerCase();
				msg = refrecordlist[refindex].msg;
				index = refindex;
			}
		}
		return {index, type, msg};
	}

	findlogpairidx = (refindex, refrecordlist, index, recordlist) => {
		// -1 no match/Ref is less priority
		// Matched/accept diff
		var refrecord = this.getlogrecord(refindex, refrecordlist);
		var reftype = refrecord.type;

		var tmptype;
		var tmptypelevel = -1;
		var tmpindex = index;

		var firstypematch = -1;

		//var typelevel = ["log", "input", "button", "errortrap", "checkpoint", "marker", "end", "start"];
		//var typelevel = ["input", "errortrap", "checkpoint", "marker", "end", "start"];
		var typelevel = ["input", "checkpoint", "marker", "end", "start"];

		if (refrecord.index < 0) {
			// Reference is empty, just match
			return {index, class:"mismatch"};
		}
		var reftypelevel = typelevel.indexOf(reftype);
		while (true) {
			if (tmpindex >= recordlist.length) {
				// No more records, unmatched
				break;
			}
			tmptype = recordlist[tmpindex].type.toLowerCase();
			tmptypelevel = typelevel.indexOf(tmptype);
			if (reftype === tmptype) {
				if (recordlist[tmpindex].msg === refrecord.msg) {
					return {index: tmpindex ,class:"match"};
				} else if (firstypematch<0) {
					// Note type match
					firstypematch = tmpindex;
				}
			} else if (tmptypelevel>reftypelevel) {
				// Ref list at a more priority match
				break;
			}
			tmpindex = tmpindex + 1;
		}
		return {index: firstypematch,class:"mismatch"};
	}

	generateLog = (tmprecordleft, tmprecordright, showlog) => {
		var output = [];

		var ltmpmatchinfo = {};
		var rtmpmatchinfo = {};

		var ltmpindex = 0;
		var ltmprecord = {};
		var lindex = 0;

		var rtmpindex = 0;
		var rtmprecord = {};
		var rindex = 0;

		var rowtypeclass = "mismatch";
		var rownum = 0;

		while (lindex < tmprecordleft.length || rindex < tmprecordright.length) {
			if (showlog !== true) {
				// Skip log type
				while(lindex < tmprecordleft.length) {
					if (tmprecordleft[lindex].type.toLowerCase() !== "log") {
						break;
					}
					lindex++;
				}
				while(rindex < tmprecordright.length) {
					if (tmprecordright[rindex].type.toLowerCase() !== "log") {
						break;
					}
					rindex++;
				}
			}

			// Find best pairs
			rowtypeclass = "mismatch";
			rtmpmatchinfo = this.findlogpairidx(lindex, tmprecordleft, rindex, tmprecordright);
			ltmpmatchinfo = this.findlogpairidx(rindex, tmprecordright, lindex, tmprecordleft);

			if (ltmpmatchinfo.class === "match") {
				rowtypeclass = ltmpmatchinfo.class;
				ltmpindex = ltmpmatchinfo.index;
				rtmpindex = rindex;
			} else {
				rowtypeclass = rtmpmatchinfo.class;
				rtmpindex = rtmpmatchinfo.index;
				ltmpindex = lindex;
			}

			// Pad
			while (rtmpindex > rindex) {
				output.push({rownum, lindex:-1, rindex, ltype:"",rtype: tmprecordright[rindex].type.toLowerCase(),rowtypeclass:"mismatch"});
				rownum++;
				rindex++;
			}
			while (ltmpindex > lindex) {
				output.push({rownum, lindex, rindex: -1, ltype:tmprecordleft[lindex].type.toLowerCase(),rtype: "",rowtypeclass:"mismatch"});
				rownum++;
				lindex++;
			}

			ltmprecord = this.getlogrecord(ltmpindex, tmprecordleft);
			rtmprecord = this.getlogrecord(rtmpindex, tmprecordright);

			output.push({rownum, lindex, rindex: rtmpindex, ltype: ltmprecord.type, rtype: rtmprecord.type, rowtypeclass});
			rownum++;

			if (ltmpindex>=0) {
				lindex++;
			}
			if (rtmpindex>=0) {
				rindex++;
			}
		}
		return output;
		//console.log(output);
	}

	displayLogRow = (rownum, lindex, rindex, ltype,rtype,rowtypeclass) => {
		//console.log(rownum, lindex, rindex, ltype,rtype,rowtypeclass);
		return <>
				{ ((rowtypeclass === "mismatch" || this.state.showdiffonly === false) ) &&
					<div key={"logresultrow"+rownum} className={styles.logresultrow+ " "+ styles["logresultrow"+rowtypeclass]}>
						<div key={"logresultrowleft"+rownum} className={styles["logresultrowleft"] + " " + styles.logresultrowitem + " " + styles["logresultrowitem"+ltype]}>
							{this.displayLogItem("left", this.state.recordleft, lindex, ltype, this.state.recordlefttype, this.state.recordleftid)}
						</div>
						<div key={"logresultrowright"+rownum} className={styles["logresultrowright"] + " " + styles.logresultrowitem + " " + styles["logresultrowitem"+rtype]}>
							{this.displayLogItem("right", this.state.recordright, rindex, rtype, this.state.recordrighttype, this.state.recordrightid)}
						</div>
					</div>
				}
			</>
	}

	displayLogItem = (sideid, data, index, type, datatype, dataid) => {
		var timeinfo = "";
		var typeinfo = "";
		var imgbaseurl = "https://static.iamnetph.com/testdata/"+datatype+"/selenium/negosyonowtest/"+dataid+"/";
		if (index < 0 || index >= data.length) {
			return <>-</>;
		}

		var tmprefdate = new Date(data[0].timestamp);
		var tmplogdate = new Date(data[index].timestamp);
		timeinfo = "["+Math.floor((tmplogdate.getTime()-tmprefdate.getTime())/1000)+"s]";
		if (type !== "button" && type !== "end") {
			typeinfo = type;
		}
		if (datatype === "reference") {
			tmprefdate.setTime(tmprefdate.getTime()+8*3600000);
			var refdatestring = tmprefdate.toISOString();
			refdatestring = refdatestring.split(".")[0];
			// Reuse image paths from output to prevent duplications
			imgbaseurl = "https://static.iamnetph.com/testdata/output/selenium/negosyonowtest/"+dataid+"_"+refdatestring.replace("T","_")+"/";
		}

		return <>
			{timeinfo !== "" && <div className={styles.logresultrowitemtime+" font-size-tiny"}>{timeinfo}<span className={styles["logresultrowitemtime"+type]}>{typeinfo}</span></div>}
			<div className={styles.logresultrowitemmsg+" "+styles["logresultrowitemmsg"+type]+" font-size-regular"}>
				{
					type === "input" ? 
						this.displayInputData(sideid, index, data[index].msg)
					:
					data[index].msg
				}
				{data[index].image && 
					<a className={styles.logresultrowitemimg} href={imgbaseurl+data[index].image+".png"} rel="noopener noreferrer" target="_blank">
						<img alt="log" src={imgbaseurl+data[index].image+".png"} />
					</a>}

			</div>
			</>
	}

	displayInputData = (sideid, index, strdata) => {
		var curobject = JSON.parse(strdata);
		return <>
				<ul className={styles.logresultrowitemmsginputdetail+ " font-size-small"}>
				{
					curobject.map((data, index) => {
						return <li>
							<strong>{data.name+":"}</strong><span>{data.value}</span>
						</li>
					})
				}
				</ul>
				<button onClick={()=>this.toggleRowInputRaw(sideid, index)} className={styles.logresultrowitemmsginputtoggle+ " font-size-small"}>Raw Data</button>
				{
					this.state["showinput"+sideid]["in"+index] &&
					<div className={styles.logresultrowitemmsginputraw}>{strdata}</div>
				}
			</>
	}

	displayFileList = (curlist, listtype, mobile) => {
		return <>{
			curlist.map((data,index) => {
					return <>{data.mobile === mobile &&
						<li key={"listitem"+index} className={styles.logfileitem}>
							<span>{data.name}</span>
							{data.browser !== "" && <div>
								<span className={"font-size-tiny"}>{" ["+data.browser+"]"}</span>
							</div>}
							{ data.id !== "." && <ul>
								<li>
									<button className={"buttonlink"} onClick={()=>this.loadResult(listtype, data.id, "left")}>{"Left"}</button>
								</li>
								<li>
									<button className={"buttonlink"} onClick={()=>this.loadResult(listtype, data.id, "right")}>{"Right"}</button>
								</li>
							</ul>}
						</li>
					}</>
			})
		}</>
	}

	fetchFiles = () => {
		this.fetchReference();
		this.fetchOutput();

	}

	fetchReference = () => {
		this.setState(state => ({ 
			listreference: loadingFileList
		}), function () {
			this.state.loginObj.getToken()
			.then((tokenresponse) => {
				var loadparam = {
					key: "reference/selenium/negosyonowtest/"
				};
				requestJSON.send("https://api.iamnet.ph/document/testdata/", "POST", loadparam, tokenresponse)
				.then((response) => {
					this.setState(state => ({ 
						listreference: response
					}));
				}).catch((err)=>{
					console.log(err);
				})
			})
		});

	}


	fetchOutput = () => {
		this.setState(state => ({ 
			listoutput: loadingFileList
		}), function () {

			this.state.loginObj.getToken()
			.then((tokenresponse) => {
				var loadparam = {
					key: "output/selenium/negosyonowtest/"
				};
				requestJSON.send("https://api.iamnet.ph/document/testdata/", "POST", loadparam, tokenresponse)
				.then((response) => {
					this.setState(state => ({ 
						listoutput: response
					}));
				}).catch((err)=>{
					console.log(err);
				})
			})
		});
	}


	loadResult = (type, id, side) => {
		if (side === "left") {
			this.setState(state => ({ 
				recordlefttype: "",
				recordleftid: "Loading...",
				recordleft: []
			}));
		} else {
			this.setState(state => ({ 
				recordrighttype: "",
				recordrightid: "Loading...",
				recordright: []
			}));
		}

		this.state.loginObj.getToken()
		.then((tokenresponse) => {
			var tmpoutput = [];
			var loadparam = {
				key: type+"/selenium/negosyonowtest/"+id+"/logfile.js",
				postproc: "logfile"
			};
			requestJSON.send("https://api.iamnet.ph/document/testdata/", "POST", loadparam, tokenresponse)
			.then((response) => {
				if (side === "left") {
					tmpoutput = this.generateLog(response, this.state.recordright, this.state.showlogentry);
					this.setState(state => ({ 
						recordlefttype: type,
						recordleftid: id,
						recordleft: response,
						compareoutput: tmpoutput
					}));
				} else {
					tmpoutput = this.generateLog(this.state.recordleft, response, this.state.showlogentry);
					this.setState(state => ({ 
						recordrighttype: type,
						recordrightid: id,
						recordright: response,
						compareoutput: tmpoutput
					}));
				}
			}).catch((err)=>{
				console.log(err);
				if (side === "left") {
					tmpoutput = this.generateLog([], this.state.recordright, this.state.showlogentry);
					this.setState(state => ({ 
						recordlefttype: "",
						recordleftid: "",
						recordleft: [],
						compareoutput: tmpoutput
					}));
				} else {
					tmpoutput = this.generateLog(this.state.recordleft, [], this.state.showlogentry);
					this.setState(state => ({ 
						recordrighttype: "",
						recordrightid: "",
						recordright: [],
						compareoutput: tmpoutput
					}));
				}
			})
		})
	}


	render() {
		return (
			<Layout showtitle={true} showtitleback={true} activePath='testresult' fullPath='testresult/negosyonow-selenium' pageTitle="NegosyoNow Selenium Runs" privatePage={true}>
				<GatedContent>
					<div className={styles.logcontainer}>
						<ul className={styles.logfilelist+" font-size-small"}>
							<button onClick={()=>this.reloadFiles()} className={"buttonlink "+styles.logfilelisttoggle+ " font-size-small"}>Reload Files</button>
							<div className={styles.logfilelistheading+" font-size-regular"}>Reference</div>
							<hr/><br/>
							<div className={styles.logfilelistheading+" font-size-regular"}> - Desktop</div>
							{
								this.displayFileList(this.state.listreference, "reference", false)
							}
							<hr/><br/>
							<div className={styles.logfilelistheading+" font-size-regular"}> - Mobile</div>
							{
								this.displayFileList(this.state.listreference, "reference", true)
							}
							<hr/><br/>
							<div className={styles.logfilelistheading+" font-size-regular"}>Outputs</div>
							<hr/><br/>
							<div className={styles.logfilelistheading+" font-size-regular"}> - Desktop</div>
							{
								this.displayFileList(this.state.listoutput, "output", false)
							}
							<hr/><br/>
							<div className={styles.logfilelistheading+" font-size-regular"}> - Mobile</div>
							{
								this.displayFileList(this.state.listoutput, "output", true)
							}
						</ul>

						<div className={styles.logresult}>
							<div className={"text-centered"}>
								<button onClick={()=>this.toggleLogItems()} className={"buttonlink "+styles.logfilelisttoggle+ " font-size-small"}>{this.state.showlogentry? "Hide Log Items": "Show Log Items"}</button>
								<button onClick={()=>this.toggleDiffItems()} className={"buttonlink "+styles.logfilelisttoggle+ " font-size-small"}>{this.state.showdiffonly? "Show All": "Show Diff Only"}</button>
							</div>

							<div className={styles.logresultrow+" "+styles.logresultrowtitle}>
								<div className={styles["logresultrowleft"] + " " + styles.logresultrowitem + " " + styles["logresultrowitemtitle"]}>
									<h2>{this.state.recordleftid.replace(/_/g," ")}</h2>
								</div>
								<div className={styles["logresultrowright"] + " " + styles.logresultrowitem + " " + styles["logresultrowitemtitle"]}>
									<h2>{this.state.recordrightid.replace(/_/g," ")}</h2>
								</div>
							</div>
							{this.state.compareoutput.map((entry) => {
									return this.displayLogRow(entry.rownum, entry.lindex, entry.rindex, entry.ltype,entry.rtype,entry.rowtypeclass);
							})}
						</div>
					</div>
				</GatedContent>
			</Layout>
		)
	}
}



